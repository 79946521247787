var jxapis = {
  全民解析: "https://jx.aidouer.net/?url=",
  go: "https://jx.playerjy.com?url=",
  g: "https://www.8090g.cn/?url=",
  g2: "https://www.8090g.cn/jiexi/?url=",
  ckmov: "https://www.ckmov.vip/api.php?url=",
  m3u8: "https://jx.m3u8.tv/jiexi/?url=",
};

export default jxapis;
